/* eslint-disable import/prefer-default-export */
import './src/styles/normalize.css';
import './src/styles/global.css';
import './src/styles/layout.css';
import './src/styles/grid.css';
import './src/styles/tailwind.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@vidstack/react/player/styles/base.css';

import './src/styles/fonts.css';

import ReactDOM from 'react-dom/client';

export const replaceHydrateFunction = () => (element, container) => {
  const root = ReactDOM.createRoot(container);
  root.render(element);
};

export const shouldUpdateScroll = () => {
  // if the new route is part of the list above, scroll to top (0, 0)
  window.scrollTo(0, 0);

  return false;
};
